<template>
  <div>
    <div align='right' class="mt-3" v-if='isEditing'>
      <l-button size="sm" type="primary" @click="save()" class="px-2">Save</l-button>
      <l-button size="sm" type="default" outline @click="cancel()" class="ml-3 px-2">Cancel</l-button>
    </div>
    <div align='right' class="mb-3" v-if='!isEditing'>
      <l-button size="sm" type="primary" @click="edit()" class="px-2">Edit</l-button>
      <l-button size="sm" type="default" outline @click="back()" class="ml-3 px-2">Back</l-button>
    </div>

    <h6>Date: {{data.input_timestamp}}</h6>
    <vue-tabs @tab-change="handleTabChange" v-if='form'>
      <v-tab title='Note List' name='noteList' class="mt-3" >
        <p class="mb-1"><b>Complaint</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="chief_complaint" v-model="form.chief_complaint" rows="2" data-vv-as="Complaint" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Present Illness</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="present_illness" v-model="form.present_illness" rows="2" data-vv-as="Present Illness" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Past Medical History</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="past_history" v-model="form.past_history" rows="2" data-vv-as="Past Medical History" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Personal Medical History</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="personal_history" v-model="form.personal_history" rows="2" data-vv-as="Personal Medical History" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Current medication in our hospital</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="personal_history_hospitalMedication" v-model="form.personal_history_hospitalMedication" rows="2" data-vv-as="Current medication in our hospital" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Non-hospital medication</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="personal_history_nonHospitalMedication" v-model="form.personal_history_nonHospitalMedication" rows="2" data-vv-as="Non-hospital medication" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Family History</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="family_history" v-model="form.family_history" rows="2" data-vv-as="Family History" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Systematic</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="review_of_system" v-model="form.review_of_system" rows="2" data-vv-as="Systematic" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Physical Examination</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="physical_examination" v-model="form.physical_examination" rows="2" data-vv-as="Physical Examination" v-validate="'max:6800'" :disabled="!isEditing" />

        <br />
        <br />
        <p><b>Laboratory Examination</b></p>
        <hr />

        <p class="mb-1"><b>Blood</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="laboratory_findings_blood" v-model="form.laboratory_findings_blood" rows="6" data-vv-as="Blood" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Biochemistry</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="laboratory_findings_biochemistry" v-model="form.laboratory_findings_biochemistry" rows="6" data-vv-as="Biochemistry" v-validate="'max:6800'" :disabled="!isEditing" />
        <p class="mb-1"><b>Laboratory Tests(Microscopic Serology)</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="laboratory_tests" v-model="form.laboratory_tests" rows="6" data-vv-as="Laboratory Tests(Microscopic Serology)" v-validate="'max:6800'" :disabled="!isEditing" />
      </v-tab>
      <v-tab title='Clinical Assumptions' name='clinicalAssumptions' class="mt-3" >
        <p class="mb-1"><b>Descriptions</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="clinical_assumption" v-model="form.clinical_assumption" rows="12" data-vv-as="Descriptions" v-validate="'max:6800'" :disabled="!isEditing" />
      </v-tab>
      <v-tab title='Treatment Plan' name='treatmentPlan' class="mt-3" >
        <p class="mb-1"><b>Descriptions</b></p>
        <el-input class="mb-3 input-disabled-text-black" type="textarea" name="treatment_plan" v-model="form.treatment_plan" rows="12" data-vv-as="Descriptions" v-validate="'max:6800'" :disabled="!isEditing" />
      </v-tab>
    </vue-tabs>

  </div>
</template>
<script>
 /* eslint-disable */ 


import lib from '../../../lib'
import Vue from "vue"


export default {
  props: ['data'],
  data () {
    return {
      isEditing: false,
      form: null,
    }
  },
  components: {
  },
  async mounted() {
    this.createForm()
  },
  methods: {
    createForm() {
      this.form = {}
      this.form.chief_complaint = this.data.chief_complaint
      this.form.present_illness = this.data.present_illness
      this.form.past_history = this.data.past_history
      this.form.personal_history = this.data.personal_history
      this.form.personal_history_hospitalMedication = this.data.personal_history_items[0].hospitalMedication
      this.form.personal_history_nonHospitalMedication= this.data.personal_history_items[0].nonHospitalMedication
      this.form.family_history = this.data.family_history
      this.form.review_of_system = this.data.review_of_system
      this.form.physical_examination = this.data.physical_examination
      this.form.laboratory_findings_blood = this.data.laboratory_findings_blood
      this.form.laboratory_findings_biochemistry = this.data.laboratory_findings_biochemistry
      this.form.laboratory_tests = this.data.laboratory_tests
      this.form.clinical_assumption = this.data.clinical_assumption
      this.form.treatment_plan = this.data.treatment_plan
    },
    // async getInfos(){
    //   var result = await lib.generalInfoListOfUser(this.data.user_id, this.pagination.currentPage - 1)
    //   if (result.status == Vue.Constants.HttpStatus.Success) {
    //     var data = result.data
    //     this.infos = data.data

    //     this.pagination.perPage = data.page_size
    //     this.pagination.currentPage=  data.page + 1
    //     this.pagination.total = data.total_size
    //   }
    // },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    save() {
      this.isEditing = false
    },
    cancel() {
      this.createForm()
      this.isEditing = false
    },
    edit() {
      this.isEditing = true
    },
    back() {
      this.$emit('back')
    },
    handleTabChange(tabIndex, newTab, oldTab) {
    },
  }
}
</script>
