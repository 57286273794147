import Vue from 'vue'
import VueRouter from 'vue-router'
import LightBootstrap from './light-bootstrap-main'
import Axios from 'axios'
// Plugins
import App from './App.vue'
import store from './store/index'
import lib from './lib.js'

import { mixinLib } from './mixinLib.js'

// import swal from 'sweetalert2';
// router setup
import routes from './routes/routes'
// plugin setup
Vue.use(VueRouter)
Vue.use(LightBootstrap)

Vue.mixin(mixinLib)

// import Constants
import constant from "@/config/constant"
Vue.use(constant)

if (sessionStorage.getItem('user')) {
  store.commit('set_user', sessionStorage.getItem('user'))
}
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active'
})

Axios.defaults.headers.common['Authorization'] = (lib.getUser() !== null) ? 'Bearer ' + lib.getUser().accessToken : null

//check auth
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && lib.getUser() === null) {
    next('/login')
  } else {
    // if (lib.getUser()){
    //   lib.getLanguageList()
    // }
    next()
  }
})

Axios.interceptors.request.use(config => {
  if (lib.getUser() !== null) {
    config.headers.common['Authorization'] = 'Bearer ' + lib.getUser().accessToken
  }
  return config
}, error => {
  return Promise.reject(error)
})

Axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        store.commit('del_user')
        router.replace({
          path: '/login',
          query: { redirect: router.currentRoute.fullPath }
        })
    }
    return Promise.reject(error.response)
  }
})


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})

