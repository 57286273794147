<template>
  <div>
    <div class="row">
      <div class="col-10">
        <div class="row">
          <div class="col-5">
            <p>NO: {{data.sys_id}}</p>
          </div>
          <div class="col-3">
            <p>Case Number: {{data.userId}}</p>
          </div>
          <div class="col-3">
            <p>Department: {{data.department}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <p>Name: {{data.name}}</p>
          </div>
          <div class="col-3">
            <p>Birthday: {{data.birth}}</p>
          </div>
          <div class="col-3">
            <p>Gender: {{data.gender}}</p>
          </div>
        </div>
      </div>
      
      <div class="col-2">
        <div align='right'>
        <l-button size="sm" type="default" outline @click="back()" class="px-2">Back</l-button>
        </div>
      </div>
    </div>
    <hr>
    <div class="container d-flex">
      <p class="line-break">{{data.output}}</p>
    </div>

  </div>
</template>
<script>
 /* eslint-disable */ 



export default {
  props: ['data'],
  // data () {
  //   return {
  //   }
  // },
  components: {
  },
  async mounted() {
  },
  methods:{
    back() {
      this.$emit('back')
    },
    replaceWithBr() {
      return this.data.output.replace(/\n/g, "<br />")
    }
  }
}
</script>
<style>
  .line-break {
    white-space: pre-wrap;
  }
</style>
