<template>
  <div>
    <div v-show='!is_edit'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px'>活動資訊</div>
        <div class="align-right">
          <button type="button" @click="handleNew()"  class="btn btn-fill btn-large btn-info" v-if="checkScope(['activity:c'])">新增</button>
      </div>
    </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData" @sort-change="changeSort" sortable="custom">
            <el-table-column type="index" width="100">
            </el-table-column>
            <el-table-column prop="is_enable"
                             :formatter="getEnableText"  
                             label="狀態"
                             sortable
                             >
            </el-table-column>
            <el-table-column label="圖片"  width="300px">
              <div class="td-actions" slot-scope="props"  >
                <img :src=props.row.image class='BannerImage' width="300px"/>
              </div>
            </el-table-column>
            <el-table-column prop="title"
                             label="標題">
            </el-table-column>
            <el-table-column
              label="功能">
              <div class="td-actions" slot-scope="props">
                <a v-tooltip.top-center="'編輯'" class="btn btn-warning btn-link btn-xs"
                  @click="handleEdit(props.row)"  v-if="checkScope(['activity:u'])" >
                  <i class="fa fa-edit"></i>
                </a>
                <a v-tooltip.top-center="'下架'" class="btn btn-warning btn-link btn-xs" v-show="props.row.is_enable" 
                  @click="handleOffline(props.row.id)" v-if="checkScope(['activity:u'])">
                  <i class="fa fa-arrow-circle-down" style='color:red'></i>
                </a>

                <a v-tooltip.top-center="'刪除'" class="btn btn-danger btn-link btn-xs"
                   @click="handleDelete(props.row.id, props)" v-if="checkScope(['activity:d'])">
                <i class="fa fa-close" style='color:red'></i>
              </a>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category"></p>
          </div>
          <l-pagination class="pagination-no-border"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      @input='handleIndexChange'
                      :total="pagination.total" />
        </div>
    </div>
    <div v-if='is_edit'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px'>{{getTitle()}}</div>
        <div class="align-right">
          <button type="button" @click="editCancel()" class="btn btn-fill btn-info" style='margin-right: 10px;'>取消</button>
          <button type="submit" @click.prevent="validate" v-show='is_edit' class="btn btn-fill btn-info"  v-if="checkScope(['activity:u'])">{{getSubmitText}}</button>
      </div>
    </div>
      <div class="col-sm-12 row">
        <div class="col-sm-6 " style='padding:0'>
          <fg-input label="標題"
                    type="text"
                    name="title"
                    data-vv-as="標題"
                    class=''
                    v-model="form.title"   
                    v-validate="{ required: true }"
                    :error="getError('title')"
                    required>
          </fg-input>
          <fg-input label="連結網址"
                type="text"
                name="link"
                data-vv-as="連結網址"
                class=''
                v-model="form.link"   
                v-validate="{ required: true, url: true }"
                :error="getError('link')"
                required>
            </fg-input>
        </div>
        <div class="col-sm-6">

          <div class="form-group has-label">
            <label> 圖片 <span>*</span></label>
            </div>
                <input type='text' v-bind:value="form.image" style='display:none;'  data-vv-as="image"  v-validate.persist="'required'">
                <img src="static/img/default-image.jpg" v-show="!form.image" width='150px'>
                <img v-bind:src="form.image" v-show="form.image" width='150px'>
                <div class='text-danger invalid-feedback' style="display:block" v-show="errors.has('image')">{{ errors.first(`image`) }}</div>
                <ImageUpload @upload="handleImageUpload(...arguments)" v-bind:imageVaildation="{ size: 2 }" ></ImageUpload>
        </div>
      </div>
        <fg-input label="服務內容文字" :error="getError(`content`)" required>
          <el-input type="textarea" name="content" v-model="form.content" rows="8"  data-vv-as="條款內容"  v-validate="'required|max:6800'" v-bind:disabled="!is_edit" ></el-input>
        </fg-input>

    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 
  import { Dialog, Table, TableColumn, MessageBox, Select, Option } from 'element-ui'
  import { Pagination as LPagination, ImageUpload, LanguageSelector } from 'src/components/index'
  import lib from '../../../lib'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      LPagination,
      ImageUpload,
      LanguageSelector
    },
    data () {
      return {
        pagination: {
          perPage: 20,
          currentPage: 1,
          total: 0
        },
        form:{
          id: null,
          image: null,
          title: null,
          content: null,

        },
        searchForm:{
        },
        query: {
          orderBy: null
        },
        is_edit: false,
        tableData: []
      }
    },
   async mounted () {
      await this.getData()
      this.is_edit = false
    },
    watch:{
    },
    computed: {
      getSubmitText(){
        var is_enable = this.form.is_enable
        return (!is_enable && this.form.id)?'重新上架':'儲存'
      }
    },
    methods: {
      getTitle (){
        return (this.form.id == '')?'新增活動資訊':'編輯活動資訊'
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      getEnableText(row, idx){
        var is_enable = row.is_enable
        return (is_enable)?'上架中':'已下架'
      },
      validate () {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return;
          if (this.form.id === ''){
            _this.create()
          } else{
            _this.update()
          }
        })
      },
      handleImageUpload(result, data){
        if (result){
          if (typeof this.form.image == "undefined")  this.$set(this.form, 'image', '')
          this.form.image = data
        }else{
          swal('上傳失敗', data, 'error')
        }
      },
      editCancel (){
        this.is_edit = false
      },
      async changeSort (val) {
        let orderBy = ''
        if (val.order && val.prop){
          orderBy = val.prop
          if (val.order == 'descending') orderBy +=',desc'
        }
        this.query.orderBy = orderBy
        await this.getData();
      },
      async handleIndexChange() {
        await this.getData()
      },
      async handleNew () {
        await this.clearForm()
        this.form = {}
        this.is_edit = true
        this.$validator.errors.clear()
      },
      async handleEdit (row) {
        await this.clearForm()
        this.is_edit = true
        this.$validator.errors.clear()
        this.form = row
        window.scroll(0,0)
      },
      async getData () {
        let query = {}
        query.page =  this.pagination.currentPage
        query.limit = this.pagination.perPage
        query.orderby = this.query.orderBy

        var result = await lib.getActivityList(query)
        if (result.code == 0){
          var data = result.data
          this.tableData = data
          this.pagination.total = result.pages.total
        }
      },
      async clearForm(){
        var _this = this
        return new Promise(function(resolve, reject){  
            _this.form.id = ''
          resolve()
        })
      },
      async create(){
        var result = await lib.createActivity(this.form);
        if (result.code == 0){
          await this.getData()
          this.is_edit = false
          swal('新增成功', '', 'success')
        }else {
          swal('新增失敗', result.message, 'error')
        }
      },
      async update(){
        this.form.is_enable = true
        var result = await lib.updateActivity(this.form.id, this.form);
        if (result.code == 0){
          await this.getData()
          this.is_edit = false
          swal('已更新', '', 'success')
        }else {
          swal('更新失敗', '', 'error')
        }
      },
      async handleOffline(id){
        var confirm = await tools.confirmDialog("請確認要下架?");
          if (confirm){
            var result = await lib.updateActivity(id, {is_enable: false})
            if (result.code == 0){
              await this.getData()
              swal('已下架', '', 'success')
            }else{
              swal('下架失敗', '', 'error')
            }
        }
      },
      async handleDelete(id, porp){
        var confirm = await tools.confirmDialog("請確認要刪除?");
          if (confirm){
            var result = await lib.deleteActivity(id)
            if (result.code == 0){
              this.pagination.currentPage = 1
              await this.getData()
              swal('已刪除', '', 'success')
            }else{
              swal('刪除失敗', '', 'error')
            }
        }
      }
    }
  }
</script>
<style>
.toolsBar{
  justify-content: space-between;
  display: flex;
}

.leftTools{
  display: flex;
}

.leftTools > *{
  padding-right: 10px;
}

.BannerImage {
  max-width: 300px;
  max-height: 200px;
  padding: 0 20px;
  object-fit: contain;
}
</style>
