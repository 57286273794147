<template>
  <div>
    <h6>Date: {{data.input_timestamp}}</h6>
    <vue-tabs @tab-change="handleTabChange">
      <v-tab title='Note List' name='noteList' class="mt-3" >
        <p><b>Complaint</b><br /><pre>{{data.chief_complaint}}</pre></p>
        <p><b>Present Illness</b><br /><pre>{{data.present_illness}}</pre></p>
        <p><b>Past Medical History</b><br /><pre>{{data.past_history}}</pre></p>
        <p><b>Personal Medical History</b><br /><pre>{{data.personal_history}}</pre></p>
        <p><b>Current medication in our hospital</b><br /><pre>{{data.personal_history_items[0].hospitalMedication}}</pre></p>
        <p><b>Non-hospital medication</b><br /><pre>{{data.personal_history_items[0].nonHospitalMedication}}</pre></p>
        <p><b>Family History</b><br /><pre>{{data.family_history}}</pre></p>
        <p><b>Systematic</b><br /><pre>{{data.review_of_system}}</pre></p>
        <p><b>Physical Examination</b><br /><pre>{{data.physical_examination}}</pre></p>

        <br />
        <p><b>Laboratory Examination</b></p>
        <hr />

        <p><b>Blood</b><br /><pre>{{data.laboratory_findings_blood}}</pre></p>
        <p><b>Biochemistry</b><br /><pre>{{data.laboratory_findings_biochemistry}}</pre></p>
        <p><b>Laboratory Tests(Microscopic Serology)</b><br /><pre>{{data.laboratory_tests}}</pre></p>
        <!-- <GeneralInfoList ref="GeneralInfoList" :data='this.data' /> -->
      </v-tab>
      <v-tab title='Clinical Assumptions' name='clinicalAssumptions' class="mt-3" >
        <p><b>Descriptions</b><br /><pre>{{data.clinical_assumption}}</pre></p>
        <!-- <MedicalHistoryList ref="MedicalHistoryList" :data='this.data' /> -->
      </v-tab>
      <v-tab title='Treatment Plan' name='treatmentPlan' class="mt-3" >
        <p><b>Descriptions</b><br /><pre>{{data.treatment_plan}}</pre></p>
        <!-- <AdmissionNoteList ref="AdmissionNoteList" :data='this.data' /> -->
      </v-tab>
    </vue-tabs>

    <div align='right'>
      <l-button size="sm" type="default" outline @click="back()" class="px-2">Back</l-button>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 

  // import member from '../Components/Member'


export default {
  props: ['data'],
  data () {
    return {
      // currentPage : 'user'
    }
  },
  components: {
    // member: member
  },
  async mounted() {
    // await this.getGroup()
  },
  methods:{
    handleTabChange(tabIndex, newTab, oldTab){
      // let newTabName = newTab.$attrs.name
      // this.currentPage = newTabName
    },
    back() {
      this.$emit('back')
    },
  }
}
</script>
